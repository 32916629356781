<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'"> {{ $t("message.model") }}</p>
          <el-button type="warning" @click="closeModal()"> {{ $t("message.close") }}</el-button>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" v-loading="loadStaff">
        <el-row :gutter="20">
          <el-col
            :span="24" >
            <div class="timeline-items__right rounded-sm w-100 p-4"   :class="mode ? 'table__myday' : 'table__mynight'">
              <div class="timeline-items__right user rounded-sm w-100 p-4" v-if="attendance_work_info&&attendance_work_info.staff"  @dblclick="pushToProfile(attendance_work_info.staff)" :class="mode ? 'table__myday' : 'table__mynight'">
                <div class="info-user-left3" :class="mode ? '' : 'performance__itemnight'">
                  <div class="img">
                    <img
                      :src="
                        attendance_work_info.staff.image
                          ? baseUrl + attendance_work_info.staff.image.path
                          : 'img/avatar.jpeg'
                      "
                      alt=""
                    />
                  </div>
                  <div class="name-info-user-left">
                    <b>{{ $t('message.staff') }}</b>
                    <div class="name_u">
                    <b>{{ $t('message.fio') }}: </b>{{ attendance_work_info.staff.name + " " + attendance_work_info.staff.last_name }}
                    </div>
                    <div class="company_u" v-if="attendance_work_info.staff.company" :class="mode ? 'text__day2' : 'text__night2'">
                      <b>{{ $t('message.company') }}: </b> {{ attendance_work_info.staff.company.name }}
                    </div>
                    <div class="company_u" v-if="attendance_work_info.staff.branch" :class="mode ? 'text__day2' : 'text__night2'">
                      <b>{{ $t('message.branch') }}: </b> {{ attendance_work_info.staff.branch.name }}
                    </div>
                    <div class="company_u" v-if="attendance_work_info.staff.department" :class="mode ? 'text__day2' : 'text__night2'">
                      <b>{{ $t('message.department') }}: </b>  {{ attendance_work_info.staff.department.name }}
                    </div>
                    <div class="company_u" v-if="attendance_work_info.staff.position" :class="mode ? 'text__day2' : 'text__night2'">
                      <b>{{ $t('message.position') }}: </b>{{ attendance_work_info.staff.position.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="attendance_work_info&&attendance_work_info.reports">
                <hr>
                Ishlagan soati: {{attendance_work_info.reports.total_working_hour ?(parseInt(attendance_work_info.reports.total_working_hour ) +$t('message.hour') +' '+ ((attendance_work_info.reports.total_working_hour - parseInt(attendance_work_info.reports.total_working_hour )) *60).toFixed(0)+$t('message.minut') ):0 }}
                <br>
                Online soati: {{ attendance_work_info.reports.online_work_hour?(parseInt(attendance_work_info.reports.online_work_hour) +$t('message.hour') +' '+ ((attendance_work_info.reports.online_work_hour-parseInt(attendance_work_info.reports.online_work_hour)) *60).toFixed(0)+$t('message.minut') ):0 }}
                <br>
                <!-- Qo'shimcha ish soati: {{  attendance_work_info.reports.additional_work_hour?(parseInt(attendance_work_info.reports.additional_work_hour) +$t('message.hour') +' '+ ((attendance_work_info.reports.additional_work_hour-parseInt(attendance_work_info.reports.additional_work_hour)) *60).toFixed(0)+$t('message.minut') ):0  }} -->
              </div>
              </div>
            <div class="timeline-items__right rounded-sm w-100 p-4"  :class="mode ? 'table__myday' : 'table__mynight'" v-if="attendance_work_info&&attendance_work_info.online_works">
             <div class="title_and_button mb-3">
                <h4 class="m-0 ">{{$t('message.online_works')}}</h4>
             </div>
              <el-row :gutter="20">
                 <el-col :span="24">
                    <table
                       class="table-my-code table-bordered td-center"
                       :class="mode ? 'table__myday' : 'table__mynight'"
                       v-loading="loadStaff"
                     >
                       <thead>
                         <tr>
                           <th >
                              Id 
                           </th>
                           <th>
                            Причина
                           </th>
                           <th >
                            Статус
                           </th>
                           <th>
                            Дата
                           </th>
                           <th >
                            Рабочие часы
                           </th>
                           <th >
                            Дата создания
                           </th>
                         </tr>
                       </thead>
                       <tbody>
                        <tr
                            v-for="complaint in attendance_work_info.online_works"
                              :key="complaint.id"
                              class="cursor-pointer">
                              <td >{{ complaint.id }}</td>
                              <td >
                                {{ complaint.reason }}
                              </td>
                              <td >
                                <el-button v-if="complaint.status == 'waiting'" type="warning" size="medium"    class="remove-button"> {{$t('message.confirm')}}</el-button>
                                <el-button v-if="complaint.status == 'accept'" type="success" size="medium"    class="remove-button"> {{$t('message.approved')}}</el-button>
                                <el-button v-if="complaint.status == 'deny'" type="danger" size="medium"    class="remove-button"> {{$t('message.rejected')}}</el-button>      
                              </td>
                              <td >
                                {{ complaint.date }}
                              </td>
                              <td >
                                {{ complaint.from_time }} - {{ complaint.to_time }}
                              </td>
                              <td >
                                {{ complaint.created_at }}
                              </td>
                            </tr>
                       </tbody>
                     </table>
                 </el-col>
               </el-row>
           </div>
           <div class="timeline-items__right rounded-sm w-100 p-4"  :class="mode ? 'table__myday' : 'table__mynight'" v-if="attendance_work_info&&attendance_work_info.additional_works">
            <div class="title_and_button mb-3">
               <h4 class="m-0 ">{{$t('message.additional_works')}}</h4>
            </div>
             <el-row :gutter="20">
                <el-col :span="24">
                   <table
                      class="table-my-code table-bordered td-center"
                      :class="mode ? 'table__myday' : 'table__mynight'"
                      v-loading="loadStaff"
                    >
                      <thead>
                        <tr>
                          <th >
                             Id 
                          </th>
                          <th>
                            Тип производительности
                          </th>
                          <th >
                            Причина
                          </th>
                          <th>
                            Дата и время
                          </th>
                          <th >
                            Статус
                          </th>
                          <th >
                           Дата создания
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                       <tr
                           v-for="complaint in attendance_work_info.additional_works"
                             :key="complaint.id"
                             class="cursor-pointer">
                             <td >{{ complaint.id }}</td>
                             <td >
                              {{
                                complaint.performance_type==1?"To'lovli":"To'lovsiz"
                              }}
                             </td>
                             <td >
                                {{
                                  complaint.reason
                                    ? complaint.reason.name: ""
                                }}
                             </td>
                             <td >
                              {{ complaint.work_date_time_from}}<br v-if=" complaint.work_date_time_from">{{ complaint.work_date_time }}
                             </td>
                             <td >
                                <el-button v-if="complaint.status == 'waiting'" type="warning" size="medium"    class="remove-button">{{$t("message.confirm")}}</el-button>
                                <el-button v-if="complaint.status == 'accept'" type="success" size="medium"    class="remove-button">{{$t("message.approved")}}</el-button>
                                <el-button v-if="complaint.status == 'deny'" type="danger" size="medium"    class="remove-button">{{$t("message.rejected")}}</el-button>      
                             </td>
                             
                             
                             <td >
                               {{ complaint.created_at }}
                             </td>
                           </tr>
                      </tbody>
                    </table>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- end app-modal__body -->
    </div>
    <!-- <el-dialog
        :title="$t('message.new_m',{
               m: $t('message.journal')})"
        :visible.sync="drawerCreate"
        width="40%"
        :append-to-body="true"
        center
        ref="drawerCreate"
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      >
      <crm-create
          ref="drawerCreateChild"
          drawer="drawerCreate"
      ></crm-create>
      </el-dialog> -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";
// import CrmCreate from "./crm-create";
export default {
  name: "show-work-info",
  mixins: [drawer],
  // components: {CrmCreate},
  data() {
    return {
      drawerCreate:false,
      loadStaff: false,
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
    };
  },

  computed: {
    ...mapGetters({
      attendance_work_info: "attendanceReport/attendance_work_info",
      // list: "attendanceReport/attendance_report",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      getWorkInfoList: "attendanceReport/getWorkInfo",
      // getOneStaffOneDayAttendanceInfo: "attendanceReport/getOneStaffOneDayAttendance",
    }),
    opened(child) {
      if (!this.loadStaff) {
        this.loadStaff = true;
        this.getWorkInfoList(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
        // this.getOneStaffOneDayAttendanceInfo(child)
        //   .then((res) => {
        //     this.loadStaff = false;
        //   })
        //   .catch((err) => {
        //     this.loadStaff = false;
        //   });
      }
    },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
    closeModal() {
      this.$emit("closeWorkInfoModal", false);
    },
    // closeDialogModalNew() {
    //   this.drawerCreate =false;
    // },
  },
};
</script>
<style lang="scss" scoped>
.timeline-items__right.user {
  margin-bottom: 20px;
  .company_u{
    margin-bottom: 3px;
    font-size: 14px;
  }
  .info-user-left3 {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .img {
      margin-right: 10px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 10%;
      }
    }
  }
  .name_u {
    font-weight: bold;
    font-size: 15px;
  }
  .position_u {
    color: #8a8a8a;
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
  }
  .salary {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
}
.title_and_button{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
